import styles from "./PodcastArchiveHeader.module.css";
import { handleTrackClick } from "~lib/analytics/handleTrackClick";
import appleLogoUrl from "~/assets/apple.png";
import spotifyLogoUrl from "~/assets/spotify.png";
import pocketcastsLogoUrl from "~/assets/pocketcasts.png";
import youtubeLogoUrl from "~/assets/youtube.png";
import rssLogoUrl from "~/assets/rss.png";

const bgAssets = import.meta.glob("./*/*.jpg", {
  as: "url",
  eager: true,
});

export function PodcastArchiveHeader({
  title = "",
  description = "",
  slug = "",
  rss_url = "",
  spotify_url = "",
  apple_url = "",
  pocketcasts_url = "",
  youtube_url = "",
}) {
  const background = bgAssets[`./bg/${slug}.jpg`];
  const coverArt = bgAssets[`./cover/${slug}.jpg`];
  const feedUrls = { rss_url, spotify_url, apple_url, pocketcasts_url, youtube_url };

  return (
    <header className={styles.header}>
      <div className={styles.headerArt}>
        <img
          src={background}
          alt=""
          fetchpriority="high"
          loading="eager"
          role="presentation"
          aria-hidden="true"
          tabIndex="-1"
        />
      </div>
      <div className={styles.headerContent}>
        <div className={styles.coverArt}>
          <img
            src={coverArt}
            alt={`Cover art for ${title}`}
            fetchpriority="high"
            loading={"eager"}
          />
        </div>
        <div className={styles.info}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          <PodcastFeedCallout {...feedUrls} title={title} />
        </div>
      </div>
    </header>
  );
}

function PodcastFeedCallout({
  title,
  rss_url = "",
  spotify_url = "",
  apple_url = "",
  pocketcasts_url = "",
  youtube_url = "",
}) {
  return (
    <aside className={styles.callout}>
      <strong className={styles.calloutTitle}>Subscribe to listen</strong>
      <ul className={styles.calloutLinks}>
        <li className={styles.feed_link}>
          <a
            href={apple_url}
            className={styles.calloutLink}
            title={`${title} on Apple Podcasts`}
            rel="noopener nofollow"
            target="_blank"
            onClick={(e) => handleTrackClick("podcast-subscribe-apple", e)}
          >
            <img className={styles.feed_link_thumbnail} alt="Apple Podcasts" src={appleLogoUrl} />
          </a>
        </li>
        <li className={styles.feed_link}>
          <a
            href={spotify_url}
            className={styles.calloutLink}
            title={`${title} on Spotify`}
            rel="noopener nofollow"
            target="_blank"
            onClick={(e) => handleTrackClick("podcast-subscribe-spotify", e)}
          >
            <img className={styles.feed_link_thumbnail} alt="Spotify" src={spotifyLogoUrl} />
          </a>
        </li>
        {pocketcasts_url && (
          <li className={styles.feed_link}>
            <a
              href={pocketcasts_url}
              className={styles.calloutLink}
              title={`${title} on PocketCasts`}
              rel="noopener nofollow"
              target="_blank"
              onClick={(e) => handleTrackClick("podcast-subscribe-pocketcasts", e)}
            >
              <img
                className={styles.feed_link_thumbnail}
                alt="Pocketcasts"
                src={pocketcastsLogoUrl}
              />
            </a>
          </li>
        )}
        {youtube_url && (
          <li className={styles.feed_link}>
            <a
              href={youtube_url}
              className={styles.calloutLink}
              title={`${title} on YouTube`}
              rel="noopener nofollow"
              target="_blank"
              onClick={(e) => handleTrackClick("podcast-subscribe-youtube", e)}
            >
              <img className={styles.feed_link_thumbnail} alt="YouTube" src={youtubeLogoUrl} />
            </a>
          </li>
        )}

        <li className={styles.feed_link}>
          <a
            href={rss_url}
            className={styles.calloutLink}
            title={`${title} via RSS`}
            rel="noopener nofollow"
            target="_blank"
            onClick={(e) => handleTrackClick("podcast-subscribe-rss", e)}
          >
            <img className={styles.feed_link_thumbnail} alt="RSS" src={rssLogoUrl} />
          </a>
        </li>
      </ul>
    </aside>
  );
}

export default PodcastArchiveHeader;
